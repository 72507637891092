import { createStore } from "vuex";
import axios from "axios";
import createPersistedState from "vuex-persistedstate";
// import SecureLS from 'secure-ls';
// const ls = new SecureLS({ isCompression: false });

export default createStore({
  state: {
    token: null,
    user: null,
    isAuthenticated: false,
  },

  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
    user: (state) => state.user,
    token: (state) => state.token,
  },

  mutations: {
    setLoggedInInfo(state, data) {
      state.isAuthenticated = true;
      state.token = data.access_token	;
      state.user = data.user;
    },

    setLogout(state) {
      state.token = null;
      state.user = null;
      state.isAuthenticated = false;
      localStorage.removeItem('LastLocation')
    },

    setPopupInfo(state, data) {
      state.user.is_hr_acknowledged = data;
    },

    setProfileImage(state, data){
      state.user.profile_photo = data;
      state.user.employee_profile_picture = data;
    }
  },

  actions: {
    // Login with password
    async loginWithPWD({ commit }, data) {
      await axios
        .post(`${process.env.VUE_APP_API_ROOT}/login/`, data)
        .then((response) => {
          if (response.data.status == 200) {
            commit("setLoggedInInfo", response.data.data);
            localStorage.setItem('isPromo', true)
          } else {
            console.error("Error Email & Password:", response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // Login with OTP
    async loginWithOTP({ commit }, otpData) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_ROOT}/validate-otp/`,
          otpData
        );
        if (response.data.status === 200) {
          commit("setLoggedInInfo", response.data.data);
          localStorage.setItem('isPromo', true)
        } else {
          console.error("Error verifying OTP:", response.data.message);
        }
      } catch (error) {
        console.error("Error verifying OTP:", error);
      }
    },

    // Logout
    logout({ commit }) {
      commit("setLogout");
    },
  },

  modules: {},
  plugins: [createPersistedState({
    // storage: {
    //   getItem: (key) => ls.get(key),
    //   setItem: (key, value) => ls.set(key, value),
    //   removeItem: (key) => ls.remove(key),
    // },
  })],
});
