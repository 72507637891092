<template>
  <div class="home">
    <div
      class="min-h-screen flex flex-col gap-6 items-center justify-center w-full font-roboto"
      v-if="tab == ''"
    >
      <div class="flex flex-col gap-y-2">
        <h1 class="text-2xl font-semibold">Welcome back</h1>
        <p class="text-gray-700">Have we met before?</p>
      </div>

      <div class="flex flex-col gap-y-2 w-80">
        <button class="border rounded-md p-1 border-black px-2">
          Sign in with google
        </button>
        <!-- <button class="border rounded-md p-1 border-black px-2">
          Sign in with Apple
        </button> -->
      </div>

      <div class="flex items-center justify-center">
        <span class="w-16 border-t border-gray-300"></span>
        <p class="mx-4">or continue with email</p>
        <span class="w-16 border-t border-gray-300"></span>
      </div>



      <form class="flex flex-col gap-y-2 w-80">
        <label for="">Business Email</label>
        <input
          type="email"
          placeholder="email@example.com"
          class="p-1 px-2 border rounded-md outline-none focus:border-primary"
          required
          v-model="email"
        />

        <div class="flex flex-col gap-y-2">
          <button @click="open('otp')" :disabled="!isEmailValid" class="text-center py-3 w-full text-white bg-primary rounded-md p-1 font-semibold text-sm">Sign in with OTP</button>
          <button @click="open('pwd')" :disabled="!isEmailValid" class="text-center py-3 w-full border text-primary hover:bg-gray-100 rounded-md p-1 font-semibold text-sm">Sign in with password</button>
          <!-- <button class="text-center py-3 w-full border text-primary hover:bg-gray-100 rounded-md p-1 font-semibold text-sm">Sign in with OTP</button> -->
        </div>
      </form>

      <div class="flex flex-col gap-y-2 justify-center w-80">
        <p class="text-center text-gray-600">Lost your password ? </p>
        <p class="text-center text-gray-600">New to Writesonic? <router-link :to="{name : 'SignUp'}" class="font-semibold text-primary">Sign Up</router-link></p>
      </div>
    </div>

    <div class="min-h-screen flex flex-col gap-6 items-center justify-center w-full font-roboto" v-if="tab == 'pwd'">
      <h1 class="font-semibold text-2xl">Login with Password</h1>
      <form action="" @submit.prevent="pwdLogin" class="flex flex-col gap-y-2 w-80">
        <label for="">Business Email</label>
        <input
          type="email"
          placeholder="email@example.com"
          class="p-1 px-2 border rounded-md outline-none focus:border-primary"
          required
          v-model="email"
        />
        <label for="">Password</label>
        <input
          type="password"
          placeholder="Password"
          class="p-1 px-2 border rounded-md outline-none focus:border-primary"
          required
          v-model="password"
        />
        <button type="submit" class="bg-primary text-white p-1 rounded-md">Login</button>
      </form>
        <p class="text-gray-700">Want to try another login option? <button @click="tab = ''" class="text-primary">Click here.</button></p>

    </div>

    <div class="min-h-screen flex flex-col gap-6 items-center justify-center w-full font-roboto" v-if="tab == 'otp'">
      <h1 class="font-semibold text-2xl">Login with OTP</h1>
      <form action="" @submit.prevent="otpLogin" class="flex flex-col gap-y-2 w-80">
        <label for="">Business Email</label>
        <input
          type="email"
          placeholder="email@example.com"
          class="p-1 px-2 border rounded-md outline-none focus:border-primary"
          required
          v-model="email"
        />
        <label for="">OTP</label>
        <input
          type="text"
          placeholder="OTP"
          class="p-1 px-2 border rounded-md outline-none focus:border-primary"
          required
          v-model="otp"
        />
        <button type="submit" class="bg-primary text-white p-1 rounded-md">Login</button>
      </form>
        <p class="text-gray-700">Want to try another login option? <button @click="tab = ''" class="text-primary">Click here.</button></p>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from "vuex";


export default {
  name: "LoginPage",
  components: {},
  data(){
    return{
      email: '',
      password: '',
      tab : '',
    }
  },
  computed:{
    ...mapGetters(["isAuthenticated", "user"]),

    isEmailValid() {
      return /\S+@\S+\.\S+/.test(this.email);
    }
  },
  methods:{
    async open(tab) {
      this.tab = tab
      if (this.tab == 'otp') {
        console.log('First if');
        
        try {
          console.log("New Data");
          
          const response = await axios.post(`${process.env.VUE_APP_API_ROOT}/otp/login/`, {email: this.email});

          if (response.data.status === 200) {
            this.tab = tab;
          } else {
            console.error('Error: Failed to log in with OTP');
          }
        } catch (error) {
          
          // console.error('Request failed chjek:', error);
        }
      } else {
        this.tab = tab;
      }
    },
    ...mapActions(["loginWithOTP", "loginWithPWD"]),
    async otpLogin(){
      const userDetails = {
        email: this.email,
        otp: this.otp
      }
      await this.loginWithOTP(userDetails);

      if(this.isAuthenticated == true){
        this.$router.push({name:'DashboardView'})
      }else{
        this.errorMessage = 'Login Failed'
      }
    },
    async pwdLogin(){
      const userDetails = {
        email: this.email,
        password: this.password,
        device_token: localStorage.getItem('message_token')
      }
      await this.loginWithPWD(userDetails);

      if(this.isAuthenticated == true){
        this.$router.push({name:'DashboardView'})
      }else{
        this.errorMessage = 'Login Failed'
      }
    }
  }
};
</script>
